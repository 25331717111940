


















import ServiceCard from '@/builder/sections/section/services/components/ServiceCard.vue'
import mixins from 'vue-typed-mixins'
import Services from '../mixins/Services'
export default mixins(Services).extend({
  name: 'Services2',
  data () {
    return {
      presentWithCategories: true
    }
  },
  components: { ServiceCard }
})
